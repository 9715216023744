@font-face {
    font-family: 'lie_to_meregular';
    src: url('../fonts/lie_to_me_-_otf-webfont.woff2') format('woff2'),
         url('../fonts/lie_to_me_-_otf-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@import "bootstrap.less";
@import "custom.less";
@import "custom-responsive.less";