@media (max-width: @screen-md-max) {
	nav.section-header {
		&.parallax-background {
			.header-flexbox {
				.flexbox-nav-wrapper {
					.flexbox-nav-inner {
						min-height: 70vh;
					}
				}
			}
		}

		.header-flexbox {
			.nav-button {
				display: flex;
				margin-right: -10px;
			}

			.flexbox-nav-wrapper {
				position: fixed;
				top: 0;
				left: 0;
				height: 100%;
				width: 100%;
				overflow-y: scroll;
				background: #fff;
				z-index: 555;
				opacity: 0;
				visibility: hidden;
				transition: all 0.3s;

				.nav-button-close {
					display: block;
				}

				.flexbox-nav-inner {
					display: flex;
					flex-direction: column;
					justify-content: center;
					min-height: 100%;
					padding: 50px;
					margin-right: 0;

					.flexbox-nav {
						flex-direction: column;

						li {
							margin-right: 0px;

							a {
								padding: 10px;
								font-size: 18px;
								color: #333;
							}
						}
					}
				}

				&.active {
					opacity: 1;
					visibility: visible;
				}
			}
		}
	}

	div.section-about {
		text-align: center;

		.about-flexbox {
			.flexbox-description {
				width: 100%;

				.section-heading {
					text-align: center;
				}
			}

			.flexbox-image {
				display: none;
			}
		}
	}

	div.vip-room-section-content {
		.content-flexbox {
			.flexbox-image {
				img {
					width: 240px;
				}
			}
		}
	}
}

@media (max-width: @screen-sm-max) {

	div.o-nas-section-content {
		.content-flexbox {
			flex-direction: column;

			.flexbox-description {
				width: 100%;
				text-align: center;
				z-index: 1;

				h2 {
					font-size: 35px;
				}
			}

			.flexbox-image {
				margin-top: 30px;
				width: 100%;
				text-align: center;
			}

			&:nth-of-type(even) {
				flex-direction: column;

				.flexbox-image {
					img {
						width: 50%;
						transform: rotate(-5deg) translateX(-30%);
					}

					.img-absolute {
						position: absolute;
						top: 20px;
						left: auto;
						right: 10%;
						transform: rotate(20deg);
					}
				}
			}
		}
	}

	div.wnetrze-section-content {
		.content-description {
			h2 {
				font-size: 35px;
			}
		}
	}

	div.wydarzenie-section-content {
		.content-flexbox {
			flex-direction: column;

			.flexbox-body {
				width: 100%;

				h1 {
					font-size: 35px;
				}
			}

			.flexbox-sidebar {
				margin-top: 50px;
				width: 100%;

				.heading {
					margin: 30px 0 15px 0;

					&:first-of-type {
						margin-top: 0;
					}
				}
			}
		}
	}

	div.rezerwacje-section-content {
		.content-flexbox {
			.flexbox-description {
				h2 {
					font-size: 35px;
					font-family: 'lie_to_meregular';
				}
			}
		}
	}

	div.vip-room-section-content {
		.content-flexbox {
			.flexbox-image {
				width: 50%;

				.flexbox-image-wrapper {
					width: 220px;
					padding: 20px 20px 60px 20px;
				}
			}
		}
	}

	div.section-footer {
		text-align: center;

		.footer-flexbox {
			flex-direction: column;
			align-items: center;

			.flexbox-service {
				.list-social {
					justify-content: center;
				}
			}
		}

		.footer-credits {
			justify-content: center;
		}
	}
}

@media (max-width: @screen-xs-max) {
	body {
		background-attachment: scroll !important;

		#return-to-top {
			bottom: 30px;
			right: 30px;
			height: 40px;
			width: 40px;

			img {
				width: 40%;
			}
		}
	}

	.section-heading {
		font-size: 40px;
	}

	nav.section-header {
		&.index {
			.container {
				width: 100%;
			}

			.header-content-wrapper {
				margin: 0;

				.header-content {
					.content-logo {
						height: auto;
						width: 90%;
					}
				}
			}
		}
	}

	div.wnetrze-section-content {
		.content-description {
			h2 {
				img {
					display: none;
				}
			}
		}

		.media-flexbox {
			.flexbox-service-wrapper {
				padding: 7px 7px 50px 7px;
			}
		}
	}

	div.rezerwacje-section-content {
		.content-flexbox {
			flex-direction: column;
			text-align: center;

			.flexbox-description {
				width: 100%;
			}

			.flexbox-image {
				margin-top: 30px;
				width: 100%;
				text-align: center;

				img {
					width: 50%;
					transform: rotate(-5deg);
					padding: 7px 7px 50px 7px;
				}
			}

			&:nth-of-type(even) {
				flex-direction: column;
			}
		}
	}

	div.o-nas-section-content {
		.content-flexbox {
			.flexbox-image {
				margin-top: 50px;

				.flexbox-image-wrapper {
					padding: 20px 20px 20px 20px;
				}
			}
		}
	}

	div.vip-room-section-content {
		.heading {
			img {
				width: 300px;
			}
		}

		.content-flexbox {
			flex-direction: column;
			padding-top: 0;

			.flexbox-description {
				width: 100%;

				h3 {
					font-size: 30px;
				}

				p {
					text-align: center;
				}
			}

			.flexbox-image {
				width: 100%;
				margin: 100px 0 150px 0;

				.flexbox-image-wrapper {
					width: 300px;

					&:first-of-type {
						left: 40%;

					}

					&:nth-of-type(2) {
						right: 40%;
					}

					&:nth-of-type(3) {
						left: 40%;

					}

					&:nth-of-type(4) {
						right: 40%;


					}

					&:nth-of-type(5) {
						left: 40%;
					}

					.flexbox-service {
						width: 100%;
					}
				}
			}
		}

		.vip-room-packets {
			padding-top: 50px;

			.packet:nth-of-type(even),
			.packet:nth-of-type(odd) {
				.content-flexbox {
					flex-direction: column;

					.flexbox-description {
						width: 100%;
					}

					.flexbox-image {
						width: 80%;
						padding-top: 50px;
						margin: 0;
					}
				}
			}
		}
	}

	div.kontakt-section-content {
		.content-flexbox {
			flex-direction: column-reverse;

			.flexbox-iframe {
				height: 300px;
				width: 100%;
			}

			.flexbox-service {
				width: 100%;
				padding: 50px 15px;
				text-align: center;

				h1 {
					text-align: center;
				}
			}
		}
	}
}

@media (max-width: 600px) {
	div.section-playing {
		&.main-video {
			margin-top: 0;
		}

		.playing-flexbox {
			margin-top: 30px;
			flex-direction: column;

			a {
				margin: 20px;
			}
		}
	}

	.main-wrapper-welcome {
		position: static;
		min-height: auto;

		video {
			position: static;
			height: auto;
			width: 100%;
			transform: translate(0, 0);
		}

		.wrapper-welcome-content {
			height: auto;

			.btn-order {
				display: none;
			}
		}

		.btn-order.mobile {
			display: flex;
			justify-content: center;
			padding-top: 30px;
		}
	}

	div.section-welcome-logo {
		padding: 80px 0 100px 0;

		.logo-content {
			.content-logo {
				height: 200px;
			}
		}
	}

	div.section-offer {
		.offer-gallery {
			flex-direction: column;

			.flexbox-service {
				width: 100%;
				max-width: 320px;
			}
		}
	}

	div.vip-room-section-content {
		.vip-room-packets {

			.packet:nth-of-type(even),
			.packet:nth-of-type(odd) {
				.stripe-packet {
					margin: 40px 0 20px 0;
				}

				.content-flexbox {
					.flexbox-image {
						order: 1;
						margin: 20px 0;
					}

					.flexbox-description {
						order: 2;
					}
				}
			}
		}
	}
}

@media (max-width: 500px) {
	nav.section-header {
		.header-flexbox {
			.flexbox-social {
				display: flex;
				align-items: center;

				li {
					&:nth-child(2) {
						margin-right: 0;
					}

					&.divider,
					&:nth-child(4),
					&:nth-child(5) {
						display: none;
					}
				}
			}
		}
	}

	div.section-offer {
		.heading {
			img {
				width: 300px;
			}
		}
	}

	div.section-gallery {
		.gallery-flexbox {
			flex-direction: column;

			.flexbox-service {
				width: 100%;
				max-width: 320px;
			}
		}

		.btn {
			margin-top: 50px;
		}
	}

	div.wnetrze-section-content {
		.media-flexbox {
			.flexbox-service-wrapper {
				width: 47%;
			}
		}
	}

	div.vip-room-section-content {
		.content-flexbox {
			.flexbox-image {
				margin: 80px 0;

				.flexbox-image-wrapper {
					width: 200px;

					&:first-of-type {
						left: 35%;

					}

					&:nth-of-type(2) {
						right: 35%;
					}

					&:nth-of-type(3) {
						left: 35%;

					}

					&:nth-of-type(4) {
						right: 35%;


					}

					&:nth-of-type(5) {
						left: 35%;
					}

					.flexbox-service {
						width: 100%;
					}
				}
			}

			.vip-room-packets {

				.packet:nth-of-type(even),
				.packet:nth-of-type(odd) {
					.content-flexbox {
						.flexbox-image {
							margin: 0;
						}
					}
				}
			}
		}
	}
}

@media (max-width: 410px) {
	nav.section-header {
		.header-flexbox {
			flex-wrap: wrap;

			.flexbox-logo {
				display: flex;
				justify-content: center;
				width: 100%;
				margin-bottom: 15px;

				img {
					width: 100%;
					height: auto;
				}
			}
		}
	}

	div.o-nas-section-content {
		.content-flexbox {
			.flexbox-image {
				img {
					padding: 7px 7px 50px 7px;
				}
			}
		}
	}

	div.wydarzenia-section-content {
		.event-service {
			.service-description {
				flex-direction: column;
				text-align: center;
				padding: 15px;

				.date {
					margin-right: 0px;
					margin-bottom: 20px;
				}
			}
		}
	}
}