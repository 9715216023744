@import url("https://fonts.googleapis.com/css?family=Lato:300,400,700,900&subset=latin-ext");
@import url("https://fonts.googleapis.com/css?family=Roboto+Condensed:300,400&subset=latin-ext");

body {
  min-height: 100vh;
  font-family: "Lato", sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #111;

  &.no-scroll {
    overflow: hidden;
    max-height: 100vh;
  }

  ul,
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    padding: 0;
  }

  a {
    text-decoration: none !important;
  }

  .btn {
    padding: 15px 35px;
    border-radius: 40px;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 1.5px;
    transition: all 0.3s;
    white-space: normal;

    img {
      margin-left: 15px;
      height: 10px;
    }

    &.btn-default {
      background: @color2;
      color: #fff;
      border: 1px solid @color2;

      &:hover {
        background: darken(@color2, 10%);
        border-color: darken(@color2, 10%);
      }
    }

    &.btn-border {
      border: 1px solid @color2;
      color: #fff;

      &:hover {
        background: darken(@color2, 10%);
        border-color: darken(@color2, 10%);
      }
    }

    &.btn-blue {
      background: @color1;
      color: #555;
      border: 1px solid @color1;

      &:hover {
        background: darken(@color1, 10%);
        border-color: darken(@color1, 10%);
      }
    }

    &.btn-red {
      background: #c5161d;
      color: #cab98d;
      border: 1px solid #c5161d;

      &:hover {
        background: lighten(#c5161d, 10%);
        border-color: lighten(#c5161d, 10%);
      }
    }

    &.btn-black {
      background: #000;
      color: #fff;
      border: 1px solid #000;

      &:hover {
        background: lighten(#000, 5%);
        border-color: lighten(#000, 5%);
      }
    }

    &.btn-gold {
      background: #c1b265;
      color: #1a1a18;
      border: 1px solid #c1b265;

      &:hover {
        background: darken(#c1b265, 12%);
        border-color: darken(#c1b265, 12%);
      }
    }

    &.btn-platinium {
      background: #b2b2b4;
      color: #1a1a18;

      border: 1px solid #b2b2b4;

      &:hover {
        background: lighten(#b2b2b4, 12%);
        border-color: lighten(#b2b2b4, 12%);
      }
    }

    &.btn-small {
      padding: 8px 20px;
    }
  }

  .ck-editor-text {
    ul,
    ol {
      padding-left: 20px;
    }

    img {
      max-width: 100% !important;
    }

    * {
      margin-top: 20px;

      &:first-child {
        margin-top: 0;
      }
    }
  }

  #return-to-top {
    position: fixed;
    z-index: 444;
    bottom: 40px;
    right: 40px;
    transition: all 0.3s;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #222;
    transition: all 0.4s;
    visibility: hidden;
    opacity: 0;

    img {
      width: 35%;
    }

    &:hover {
      background: @color2;
    }

    &.active {
      opacity: 1;
      visibility: visible;
    }
  }

  .m-t-5 {
    margin-top: 5px;
  }

  .m-t-10 {
    margin-top: 10px;
  }

  .m-t-15 {
    margin-top: 15px;
  }

  .m-t-20 {
    margin-top: 20px;
  }

  .m-t-25 {
    margin-top: 25px;
  }

  .m-t-30 {
    margin-top: 30px;
  }

  .m-b-5 {
    margin-bottom: 5px;
  }

  .m-b-10 {
    margin-bottom: 10px;
  }

  .m-b-15 {
    margin-bottom: 15px;
  }

  .m-b-20 {
    margin-bottom: 20px;
  }

  .m-b-25 {
    margin-bottom: 25px;
  }

  .m-b-30 {
    margin-bottom: 30px;
  }

  .p-t-5 {
    padding-top: 5px;
  }

  .p-t-10 {
    padding-top: 10px;
  }

  .p-t-15 {
    padding-top: 15px;
  }

  .p-t-20 {
    padding-top: 20px;
  }

  .p-t-25 {
    padding-top: 25px;
  }

  .p-t-30 {
    padding-top: 30px;
  }

  .p-b-5 {
    padding-bottom: 5px;
  }

  .p-b-10 {
    padding-bottom: 10px;
  }

  .p-b-15 {
    padding-bottom: 15px;
  }

  .p-b-20 {
    padding-bottom: 20px;
  }

  .p-b-25 {
    padding-bottom: 25px;
  }

  .p-b-30 {
    padding-bottom: 30px;
  }
}

.system-alerts {
  width: 100%;
  text-align: center;

  .alert {
    border-radius: 0;
    border: none;
    height: 60px;
    display: flex;
    align-items: center;
    opacity: 0.9;
    transition: all 0.4s;

    .alert-inner {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .img-hidden {
        visibility: hidden;
        opacity: 0;
        height: 18px;
      }

      p {
        color: #fff;
        padding: 0 50px;
        font-family: 15px;
      }

      button {
        color: #fff;
        opacity: 1;
        font-weight: 300;
        float: none;

        img {
          height: 18px;
        }
      }
    }

    &.alert-success {
      background: #76ad21;
    }

    &.alert-info {
      background: #218ead;
    }

    &.alert-warning {
      background: #c52b20;
    }
  }
}

.modal-service-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  background: rgba(0, 0, 0, 0.5);
  z-index: 555;
  opacity: 0;
  visibility: hidden;
  transition: all 0.7s;

  &.active {
    opacity: 1;
    visibility: visible;
    overflow: hidden;
  }

  .modal-service {
    padding: 20px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100%;

    .service-inner {
      background: #fff;
      width: 500px;
      max-width: 100%;
      padding: 25px;
      position: relative;

      &.lg {
        width: 850px;
        min-width: 300px;
      }

      .service-button {
        position: absolute;
        z-index: 1;
        top: 20px;
        right: 20px;
        border: none;
        background: none;
        padding: 15px;
        display: flex;
        justify-content: center;
        align-items: center;

        .button-bar {
          display: block;
          position: absolute;
          width: 20px;
          height: 2px;
          background: #222;

          &:nth-child(1) {
            transform: rotate(45deg);
          }

          &:nth-child(2) {
            transform: rotate(-45deg);
          }
        }
      }

      .service-content {
        position: relative;
        padding-top: 100%;
        width: 85%;
        margin: 0 auto;

        .heading {
          padding-right: 40px;
          font-size: 16px;
          font-weight: 700;
          text-transform: uppercase;
          margin-bottom: 10px;
        }

        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: contain;
        }

        .description {
          margin-top: 20px;
        }

        .person {
          margin-top: 20px;

          a {
            font-size: 12px;
            color: #999;
            transition: all 0.3s;
            margin-top: 10px;
            display: block;

            b {
              font-size: 16px;
            }

            span {
              font-size: 10px;
            }
          }

          &.blue {
            a {
              &:hover {
                color: @color1;
              }
            }
          }

          &.pink {
            a {
              &:hover {
                color: @color2;
              }
            }
          }
        }
      }

      .service-buttons {
        margin-top: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .btn-link {
          padding: 0;
          text-transform: uppercase;
          font-weight: 700;
          text-decoration: none;
        }
      }
    }
  }
}

.section-background {
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;

  .background-inner {
    // background-image: url('../img/backgrounds/header-background.jpg');
    // background-position: center;
    // background-size: cover;
    background-color: #111;
    height: 100%;
    width: 100%;
  }
}

.section-heading {
  text-align: center;
  font-size: 65px;
  font-family: "lie_to_meregular";
  color: @color2;
  text-shadow: 0 0 2px, 0 0 1em #d774a5, 0 0 0.5em #d774a5, 0 0 0.1em #d774a5,
    0 2px 3px #000;

  span {
    animation: blink linear infinite 3s;
  }

  @keyframes blink {
    78% {
      color: inherit;
      text-shadow: inherit;
      opacity: 1;
    }

    79% {
      color: #333;
      opacity: 0.5;
    }

    80% {
      text-shadow: none;
      opacity: 0.4;
    }

    81% {
      color: inherit;
      text-shadow: inherit;
      opacity: 1;
    }

    82% {
      color: #333;
      text-shadow: none;
      opacity: 0.2;
    }

    83% {
      color: inherit;
      text-shadow: inherit;
      opacity: 1;
    }

    92% {
      color: #333;
      text-shadow: none;
      opacity: 0.2;
    }

    92.5% {
      color: inherit;
      text-shadow: inherit;
      opacity: 1;
    }
  }
}

nav.section-header {
  position: relative;
  z-index: 11;

  &.parallax-background {
    position: relative;
    background-image: url("../img/backgrounds/vip-room-header.jpg");
    min-height: 550px;
    z-index: 1;

    .nav-shadow {
      position: absolute;
      top: 0;
      width: 100%;
      transform: translate3d(0, 0, 0);
      background: rgba(0, 0, 0, 0.75);
      background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.75) 0,
        rgba(0, 0, 0, 0) 100%
      );
      width: 100%;
      height: 92px;
      z-index: -1;
    }

    .header-background {
      background-color: rgba(0, 0, 0, 0.75);

      .header-flexbox {
        padding: 30px 0 0 0;
      }
    }
  }

  &.index {
    // min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .header-background {
      background-color: rgba(0, 0, 0, 0.75);

      .header-flexbox {
        padding: 20px 0;
      }
    }

    .header-content-wrapper {
      position: absolute;
      z-index: -1;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }

    .header-scroll-down {
      padding-bottom: 50px;
      text-align: center;

      .btn {
        visibility: hidden;
      }
    }
  }

  .header-flexbox {
    padding: 30px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .flexbox-logo {
      img {
        height: 50px;
      }
    }

    .flexbox-nav-wrapper {
      .nav-button-close {
        position: absolute;
        display: none;
        width: 100%;
        top: 20px;
        left: 0;

        .container {
          display: flex;
          justify-content: flex-end;

          &:before,
          &:after {
            content: none;
          }
        }

        button {
          outline: none;
          border: none;
          background: none;
          padding: 15px;
          display: flex;
          justify-content: center;
          align-items: center;

          .button-bar {
            display: block;
            position: absolute;
            width: 30px;
            height: 2px;
            background: #222;

            &:nth-child(1) {
              transform: rotate(45deg);
            }

            &:nth-child(2) {
              transform: rotate(-45deg);
            }
          }
        }
      }

      .flexbox-nav-inner {
        .flexbox-nav {
          display: flex;
          align-items: center;

          li {
            margin-right: 35px;

            a {
              color: #fff;
              padding: 20px 0;
              display: block;
              font-size: 16px;
              font-family: "Roboto Condensed", sans-serif;
              font-weight: 300;
              transition: all 0.3s;
            }

            &.blink {
              a {
                color: #cead00;
                text-shadow: 0 0 2px, 0 0 1em #cead00, 0 0 0.3em #cead00,
                  0 0 0.1em #cead00, 0 2px 3px #000;
                animation: blink linear infinite 3s;
              }
            }

            &:nth-child(odd) {
              a {
                &:hover {
                  color: @color1;
                }
              }
            }

            &:nth-child(even) {
              a {
                &:hover {
                  color: @color2;
                }
              }
            }

            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }

    .flexbox-social {
      display: flex;
      align-items: center;

      li {
        margin-right: 20px;

        a {
          color: #fff;
          font-size: 13px;
          font-family: "Roboto Condensed", sans-serif;
          font-weight: 300;
          display: block;
          height: 20px;

          svg {
            width: 20px;
            height: 20px;
            fill: #fff;
            transition: all 0.3s;
          }
        }

        &.divider {
          height: 15px;
          width: 1px;
          background: #fff;
          opacity: 0.3;
        }

        &:nth-child(1),
        &:nth-child(4) {
          &:hover {
            a {
              color: @color1;

              svg {
                fill: @color1;
              }
            }
          }
        }

        &:nth-child(2),
        &:nth-child(5) {
          &:hover {
            a {
              color: @color2;

              svg {
                fill: @color2;
              }
            }
          }
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .nav-button {
      padding: 10px;
      border: none;
      background: none;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;
      outline: none;
      display: none;

      .button-bar {
        height: 2px;
        width: 24px;
        background: #fff;
        border-radius: 10px;
        transition: all 0.4s;

        &:nth-child(2) {
          margin-top: 4px;
          width: 27px;
        }

        &:nth-child(3) {
          margin-top: 4px;
          width: 30px;
        }
      }

      &:hover,
      &:focus {
        .button-bar {
          width: 30px !important;
        }
      }
    }
  }
}

.main-wrapper-welcome {
  position: relative;
  overflow-x: hidden;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  video {
    position: absolute;
    z-index: -1;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  //   .wrapper-welcome-content {
  //     height: 100vh;
  //     width: 100%;
  //     display: flex;
  //     flex-direction: column;
  //     justify-content: space-between;
  //     align-items: center;
  //   }

  .btn-order {
    position: absolute;

    .btn {
      padding: 20px 70px;
      font-size: 16px;
    }
  }

  .btn-order.mobile {
    display: none;
  }
}

div.section-playing {
  padding-bottom: 100px;

  .playing-flexbox {
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
    position: relative;

    a {
      text-align: center;

      img {
        width: 120px;
      }

      p {
        margin-top: 20px;
        font-family: "lie_to_meregular";
        font-size: 25px;
        color: #fff;
      }
    }
  }

  .owl-carousel {
    margin-top: 50px;
    position: relative;

    .owl-controls {
      display: none;
    }
  }
}

div.section-welcome-logo {
  padding: 150px 0 200px 0;

  .logo-content {
    display: flex;
    align-items: center;
    justify-content: center;

    .content-logo {
      height: 450px;
      animation: blink linear infinite 2s;
      filter: drop-shadow(0px 0px 10px @color2);
    }

    @keyframes blink {
      78% {
        filter: none;
        opacity: 1;
      }

      79% {
        filter: grayscale(100%);
        opacity: 0.3;
      }

      80% {
        filter: grayscale(100%);
        opacity: 0.3;
      }

      81% {
        filter: none;
        opacity: 1;
      }

      82% {
        filter: grayscale(100%);
        opacity: 0.3;
      }

      83% {
        filter: none;
        opacity: 1;
      }

      92% {
        filter: grayscale(100%);
        opacity: 0.3;
      }

      92.5% {
        filter: none;
        opacity: 1;
      }
    }
  }
}

div.section-about {
  padding: 70px 0;

  .about-flexbox {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .flexbox-description {
      width: 65%;

      .section-heading {
        text-align: left;
      }

      p {
        margin-top: 20px;
        color: #fff;
        font-weight: 300;
        font-size: 18px;
      }

      .btn {
        margin-top: 30px;
      }
    }

    .flexbox-image {
      width: 15%;

      img {
        width: 100%;
      }
    }
  }
}

div.section-offer {
  padding-bottom: 100px;

  .section-cover {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #fff;
    opacity: 0.3;
    height: 100%;
    width: 100%;
  }

  .heading {
    display: flex;
    flex-direction: column;
    align-items: center;

    h2 {
      margin-bottom: 30px;
    }

    img {
      width: 500px;
    }
  }

  .offer-gallery {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .flexbox-service {
      margin-top: 50px;
      width: 30%;
      border-radius: 10px;
      overflow: hidden;

      img {
        width: 100%;
      }
    }
  }

  .btn {
    margin-top: 50px;
  }
}

div.section-gallery {
  padding-bottom: 100px;

  // .section-cover {
  // 	position: absolute;
  // 	top: 0;
  // 	left: 0;
  // 	background-color: #ffae00;
  // 	opacity: 0.25;
  // 	height: 100%;
  // 	width: 100%;
  // }

  .gallery-flexbox {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .flexbox-service {
      margin-top: 50px;
      width: 30%;
      border-radius: 10px;
      overflow: hidden;

      img {
        width: 100%;
      }
    }
  }

  .btn {
    margin-top: 50px;
  }
}

div.o-nas-section-content {
  padding: 70px 0 140px 0;
  overflow: hidden;
  background: #fff;

  .content-flexbox {
    margin-top: 70px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .flexbox-description {
      width: 45%;
      position: relative;
      // z-index: 111;

      h2 {
        font-size: 42px;
        font-family: "lie_to_meregular";
      }

      p {
        margin-top: 20px;
        font-weight: 300;
        font-size: 18px;
      }
    }

    .flexbox-image {
      width: 45%;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;

      .flexbox-image-wrapper {
        width: 70%;
        padding: 20px 20px 80px 20px;
        background: #fff;
        box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3),
          0 15px 12px rgba(0, 0, 0, 0.22);
        margin-top: -20px;

        .flexbox-service {
          display: block;

          img {
            width: 100%;
          }
        }

        &:first-of-type {
          margin-top: 0;
          transform: rotate(10deg);
        }

        &:nth-of-type(2) {
          transform: rotate(-10deg);
        }

        &:nth-of-type(3) {
          transform: rotate(10deg);
        }
      }
    }

    &:nth-of-type(even) {
      margin-top: 120px;
      flex-direction: row-reverse;

      .flexbox-image {
        img {
          transform: rotate(5deg);
        }

        .img-absolute {
          left: auto;
          left: 70px;
          transform: rotate(-20deg);
        }
      }
    }
  }
}

div.wnetrze-section-content {
  padding: 70px 0;
  background: #fff;

  .content-description {
    padding-top: 50px;
    text-align: center;

    h2 {
      font-size: 42px;
      font-family: "lie_to_meregular";
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        height: 50px;
        margin-right: 20px;
      }
    }

    p {
      margin-top: 20px;
      font-weight: 300;
      font-size: 18px;
    }
  }

  .content-buttons {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    .btn {
      margin: 10px;
    }
  }

  .media-flexbox {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .flexbox-service-wrapper {
      margin-top: 30px;
      width: 30%;
      position: relative;
      overflow: hidden;
      padding: 20px 20px 80px 20px;
      background: #fff;
      box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3),
        0 15px 12px rgba(0, 0, 0, 0.22);

      .flexbox-service {
        width: 100%;
        padding-top: 75%;
        position: relative;
        display: block;

        .service-inner {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          display: flex;
          align-items: center;
          overflow: hidden;

          img {
            width: 100%;
          }
        }
      }

      &:nth-child(6n) {
        transform: rotate(3deg);
      }

      &:nth-child(6n-1) {
        transform: rotate(-1deg);
      }

      &:nth-child(6n-2) {
        transform: rotate(-4deg);
      }

      &:nth-child(6n-3) {
        transform: rotate(-2deg);
      }

      &:nth-child(6n-4) {
        transform: rotate(4deg);
      }

      &:nth-child(6n-5) {
        transform: rotate(1deg);
      }

      &.service-hidden {
        margin: 0;
        padding: 0;
        opacity: 0;
        visibility: hidden;
      }
    }
  }
}

div.wydarzenia-section-content {
  padding: 70px 0;

  .event-service {
    display: block;
    margin-top: 50px;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);

    .service-image {
      width: 100%;
    }

    .service-description {
      background: @color2;
      padding: 30px;
      display: flex;

      .date {
        color: @color2;
        font-weight: 900;
        background: #fff;
        padding: 10px;
        font-size: 20px;
        margin-right: 20px;
        border-radius: 5px;
        text-align: center;
        line-height: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        small {
          font-size: 12px;
          opacity: 0.5;
        }
      }

      h3 {
        color: #fff;
        font-family: "lie_to_meregular";
      }

      .more {
        margin-top: 15px;
        color: #fff;
        text-transform: uppercase;
        font-weight: 900;
      }
    }
  }
}

div.wydarzenie-section-content {
  padding: 70px 0;
  background: #fff;

  .content-flexbox {
    display: flex;
    justify-content: space-between;

    .flexbox-body {
      width: 70%;

      .main-image {
        width: 100%;
        border-radius: 10px;
        box-shadow: 0 11px 20px rgba(0, 0, 0, 0.15),
          0 15px 12px rgba(0, 0, 0, 0.1);
      }

      h1 {
        margin-top: 30px;
        color: @color2;
        font-size: 42px;
        font-family: "lie_to_meregular";
      }

      p {
        margin-top: 20px;
        font-weight: 300;
        font-size: 18px;
      }
    }

    .flexbox-sidebar {
      width: 25%;
      border-radius: 10px;
      background: @gray-lighter;
      padding: 20px;

      .heading {
        font-weight: 900;
        letter-spacing: 1px;
        opacity: 0.5;
        font-size: 13px;
        text-transform: uppercase;
        color: @gray-light;
        margin: 50px 0 20px 0;

        &:first-of-type {
          margin-top: 0;
        }
      }

      ul {
        li {
          a {
            color: #333;
            transition: all 0.3s;

            b {
              font-size: 16px;
            }

            span {
              font-size: 10px;
            }

            &:hover {
              color: @color2;
            }
          }
        }
      }

      .date {
        padding: 10px 15px;
        background: @color2;
        border-radius: 5px;

        p {
          font-weight: 700;
          font-size: 18px;
          color: #fff;
        }
      }

      .text {
        p {
          font-weight: 300;
          font-size: 18px;
        }
      }
    }
  }
}

div.rezerwacje-section-content {
  padding: 70px 0;
  overflow: hidden;
  background: #fff;

  .content-reservation {
    margin-top: 50px;
    text-align: center;

    .btn {
      margin: 5px;
    }

    p {
      margin-top: 50px;
      font-size: 18px;
    }
  }

  .content-buttons {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    .btn {
      margin: 10px;
    }
  }

  .content-image {
    margin-top: 50px;
    width: 86%;
    margin-left: 7%;
    position: relative;

    svg {
      position: absolute;
      z-index: 111;
      top: 0;
      left: 0;
    }

    .shape {
      position: relative;
      z-index: 999;
      opacity: 0;
      stroke-width: 0.5px !important;
    }

    *[class*="strefa-vip"] {
      fill: #d5c40a;

      &.shape-stroke {
        stroke: #d5c40a;
      }
    }

    *[class*="strefa-scena"] {
      fill: #d3220b;

      &.shape-stroke {
        stroke: #d3220b;
      }
    }

    *[class*="strefa-wneka"] {
      fill: #777777;

      &.shape-stroke {
        stroke: #777777;
      }
    }

    *[class*="strefa-wyspa"] {
      fill: #5a8900;

      &.shape-stroke {
        stroke: #5a8900;
      }
    }

    *[class*="strefa-antresola"] {
      fill: #fff;

      &.shape-stroke {
        stroke: #fff;
      }
    }

    *[class*="strefa-rozkosz"] {
      fill: #000;

      &.shape-stroke {
        stroke: #000;
      }
    }

    *[class*="strefa-relaks"] {
      fill: #e815dc;

      &.shape-stroke {
        stroke: #e815dc;
      }
    }

    *[class*="strefa-przy-barze"] {
      fill: #af6a19;

      &.shape-stroke {
        stroke: #af6a19;
      }
    }

    .shape-opacity {
      opacity: 0.5;
    }

    .shape-opacity-full {
      opacity: 0.5;
    }

    .shape-stroke {
      opacity: 0.5;
      fill: none !important;
    }

    a {
      cursor: pointer;
    }

    .image-size {
      width: 100%;
    }
  }

  .content-flexbox {
    padding-top: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .flexbox-description {
      width: 45%;

      h2 {
        font-size: 42px;
        font-family: "lie_to_meregular";
      }

      p {
        margin-top: 20px;
        font-weight: 300;
        font-size: 18px;
      }
    }

    .flexbox-image {
      width: 45%;
      position: relative;

      img {
        width: 320px;
        padding: 20px 20px 80px 20px;
        background: #fff;
        box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3),
          0 15px 12px rgba(0, 0, 0, 0.22);
        transform: rotate(-5deg);
      }

      .img-absolute {
        position: absolute;
        top: 20px;
        left: 140px;
        transform: rotate(20deg);
      }
    }

    &:nth-of-type(even) {
      flex-direction: row-reverse;

      .flexbox-image {
        img {
          transform: rotate(5deg);
        }
      }
    }

    &:nth-of-type(3n) {
      .flexbox-image {
        img {
          transform: rotate(-10deg);
        }
      }
    }
  }
}

div.vip-room-section-content {
  padding: 70px 0;
  overflow: hidden;
  background-color: #111;

  .heading {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;

    h1 {
      color: #cead00;
      text-shadow: 0 0 2px, 0 0 1em #cead00, 0 0 0.3em #cead00,
        0 0 0.1em #cead00, 0 2px 3px #000;
    }

    img {
      margin-top: 30px;
      width: 500px;
    }
  }

  h3 {
    font-size: 40px;
    font-family: "lie_to_meregular";
    color: #fff;
    margin: 30px 0;
  }

  ul {
    padding-left: 18px;
  }

  .content-flexbox {
    padding-top: 60px;
    display: flex;
    justify-content: space-between;

    .flexbox-description {
      width: 48%;
      text-align: left;

      p,
      li {
        margin-top: 20px;
        font-weight: 300;
        font-size: 18px;
        color: #fff;
      }
    }

    .flexbox-image {
      width: 40%;
      height: 600px;
      position: relative;

      .flexbox-image-wrapper {
        position: absolute;
        width: 320px;
        padding: 20px 20px 80px 20px;
        background: #fff;
        box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3),
          0 15px 12px rgba(0, 0, 0, 0.22);
        transform: rotate(-5deg);

        .flexbox-service {
          display: block;

          img {
            width: 100%;
          }
        }

        &:first-of-type {
          top: 0;
          left: 50%;
          transform: rotate(15deg);
        }

        &:nth-of-type(2) {
          top: 20%;
          right: 30%;
          transform: rotate(-15deg);
        }

        &:nth-of-type(3) {
          top: 40%;
          left: 50%;
          transform: rotate(15deg);
        }

        &:nth-of-type(4) {
          top: 60%;
          right: 30%;
          transform: rotate(-15deg);
        }

        &:nth-of-type(5) {
          top: 80%;
          left: 50%;
          transform: rotate(15deg);
        }
      }
    }
  }

  .vip-room-packets {
    padding-top: 100px;

    h3 {
      text-align: center;
    }

    .packet {
      .stripe-packet {
        height: 50px;
        background-size: auto 100%;

        margin: 80px 0;
      }

      &:nth-of-type(even) .content-flexbox {
        flex-direction: row-reverse;
      }

      &:first-of-type {
        .stripe-packet {
          margin: 60px 0 80px 0;
          // background: url('../img/backgrounds/red-stripe.svg')
        }
      }

      // &:nth-of-type(2) {
      //     .stripe-packet {
      //         background: url('../img/backgrounds/black-stripe.svg')
      //     }
      // }

      // &:nth-of-type(3) {
      //     .stripe-packet {
      //         background: url('../img/backgrounds/gold-stripe.svg')
      //     }
      // }

      // &:nth-of-type(4) {
      //     .stripe-packet {
      //         background: url('../img/backgrounds/platinium-stripe.svg')
      //     }
      // }
    }

    .content-flexbox {
      padding: 0;
      align-items: center;

      .flexbox-description {
        width: 45%;

        ul {
          padding-left: 20px;
        }
      }

      .flexbox-image {
        height: 100%;
        width: 45%;

        img {
          width: 100%;
          background: transparent;
          box-shadow: none;
          transform: rotate(0);
          padding: 0;
        }
      }
    }

    .content-reservation {
      display: flex;
      justify-content: center;
      margin-top: 20px;
      // font-family: 'Lato', sans-serif;
    }

    .content-buttons {
      margin-top: 30px;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      // font-family: 'Lato', sans-serif;

      .btn {
        margin: 10px;
      }
    }
  }
}

div.static-page-section-content {
  padding: 70px 0;
  background: #fff;

  .ck-editor-text {
    margin-top: 40px;

    p {
      font-weight: 300;
      font-size: 18px;
    }
  }

  .content-download {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      height: 50px;
      width: 50px;
      fill: #333;
      transition: all 0.3s;
      margin-right: 20px;
    }

    p {
      font-size: 16px;
      font-weight: 700;
      letter-spacing: 1.5px;
      color: #333;
      text-transform: uppercase;
      transition: all 0.3s;
    }

    &:hover {
      svg {
        fill: @color1;
      }

      p {
        color: @color1;
      }
    }
  }
}

div.kontakt-section-content {
  background: #fff;

  .content-flexbox {
    display: flex;

    .flexbox-iframe {
      height: inherit;
      width: 50%;

      iframe {
        height: 100%;
        width: 100%;
        border: none;
        filter: grayscale(60%);
      }
    }

    .flexbox-service {
      width: 50%;
      padding: 100px;

      h1 {
        text-align: left;
      }

      address {
        padding-left: 4px;
        margin-top: 30px;
        font-size: 20px;
        color: #666;
      }

      .person {
        margin-top: 30px;

        .heading {
          font-family: "lie_to_meregular";
          font-size: 28px;
        }

        a {
          font-size: 12px;
          color: #999;
          transition: all 0.3s;
          margin-top: 10px;
          display: block;

          b {
            font-size: 16px;
          }

          span {
            font-size: 10px;
          }
        }

        &.blue {
          a {
            &:hover {
              color: @color1;
            }
          }
        }

        &.pink {
          a {
            &:hover {
              color: @color2;
            }
          }
        }
      }
    }
  }
}

div.section-footer {
  padding: 20px 0 70px 0;
  position: relative;
  z-index: 10;

  .footer-cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #111;
  }

  .vip-room-footer {
    background: #111;
  }

  .footer-flexbox {
    display: flex;
    justify-content: space-between;
    position: relative;

    .flexbox-service {
      margin-top: 50px;

      ul {
        li {
          margin-top: 10px;

          &.heading {
            margin-top: 0;
            padding-bottom: 10px;
            font-size: 16px;
            color: #ddd;
          }

          a {
            font-size: 12px;
            color: #999;
            transition: all 0.3s;

            b {
              font-size: 16px;
            }

            span {
              font-size: 10px;
            }
          }

          p {
            font-size: 12px;
            color: #999;

            b {
              font-weight: 400;
              font-size: 14px;
            }

            span {
              font-size: 10px;
            }
          }
        }

        &.blue {
          li {
            a {
              &:hover {
                color: @color1;
              }
            }
          }
        }

        &.pink {
          li {
            a {
              &:hover {
                color: @color2;
              }
            }
          }
        }
      }

      .list-social {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        li {
          margin-right: 20px;

          a {
            img {
              height: 25px;
            }
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }

  .img-info {
    position: relative;
    margin-top: 50px;
    width: 100%;
    min-width: 350px;
  }

  .footer-credits {
    padding-top: 50px;
    border-top: 1px solid #232426;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    p {
      font-size: 12px;
      color: #999;
      font-weight: 300;

      a {
        color: @color2;
      }
    }

    .credits-logo {
      height: 20px;
    }
  }

  .alert {
    position: relative;
    margin-top: 50px;
    padding: 50px 0 0 0;
    background: none;
    border: none;
    border-top: 1px solid #232426;

    p {
      font-size: 12px;
      color: #999;
      font-weight: 300;

      a {
        color: @color2;
        cursor: pointer;
      }
    }
  }
}
